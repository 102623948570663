.App {
  text-align: center;
  width:auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
  background-color: #282c34!important;
  color: white!important;
}
.navbar-nav{
  padding: 10px;
  border-radius: 5px;
}
.nav-link.active{
  background-color: white;
  color: black!important;
}
.modal-content{
  background-color: #282c34!important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-menu{
  border: none!important;
}
.btn-menu:focus{
  box-shadow: none!important;
}
.offcanvas-footer{
  position: absolute;
  bottom: 20px!important;
  right:20px!important;
  
}
.nav-tabs .nav-link.active {
  color: white!important;
  background-color: grey!important;
  border-color: grey!important;
}
.nav-tabs .nav-link {
  color: white!important;
  border-color: grey!important;
}
.tabular{
  padding: 0!important;
  border-color: grey!important;
  border: solid 1px;
  border-top: none;
  border-radius: 8px!important;
}
.tab-content{
  padding: 20px!important;
}

.accordion-button:focus {
  z-index: 3;
  border: none!important;
  outline: 0;
  box-shadow: none!important;
}